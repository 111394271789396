// --------------------------------------------------
// Main Grid Components
// --------------------------------------------------
.site-container {
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-grid {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

.main-aside {

}

.has-aside .main {
  position: relative;
  width: 100%;
  padding-left: 300px;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;

  & > section {
    border-left: 1px solid $lighter;
  }

  @media screen and (max-width: 1204px) {
    padding-left: 0;

    & > section {
      border-left: none;
    }
  }
}

@media screen and (max-width: 1204px) {
  .main-aside.show + .main {
    -webkit-transform: translate(300px);
    -ms-transform: translate(300px);
    transform: translate(300px);
  }
}



// --------------------------------------------------
// With Aside
// --------------------------------------------------
.has-aside {

}

.is-aside-active {

}
