//Footer
.site-footer {
  background-color: $dark;
  padding: 60px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    padding: 40px 20px;
  }

  .footer-container {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto 65px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }

    @media (max-width: 550px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .footer-column {
    width: calc(25% - ((60px * 3) / 4));

    @media (max-width: 1000px) {
      width: calc(50% - 15px);
      margin-bottom: 30px;
    }

    @media (max-width: 550px) {
      width: 100%;
    }
  }

  .footer-title {
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 600;
    color: #929292;
    text-transform: uppercase;
    padding: 0 0 10px;
    border-bottom: 1px solid #525252;
    margin: 0 0 10px;
  }

  .nav-link {
    display: block;
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 500;
    color: white;
    padding: 12px 0;
    transition: color ease-out .2s;

    i {
      margin-right: 12px;
      font-size: 16px;
    }

    &:hover {
      color: $colorAccent1;
    }
  }

  .footer-bottom {
    display: flex;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .5px;
    color: rgba(white, .75);
    font-weight: 500;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-legal {
    margin: 0;

    @media (max-width: 550px) {
      margin: 0 0 5px;
    }

    &:after {
      content: '-';
      margin: 0 10px;

      @media (max-width: 550px) {
        display: none;
      }
    }
  }

  .footer-rights {
    color: rgba(white, .75);
    font-weight: 500;
    transition: color ease-out .2s;

    &:hover {
      color: $colorAccent1;
    }
  }
}