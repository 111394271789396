// --------------------------------------------------
// Title
// --------------------------------------------------

b,
strong {
  font-weight: 500;
}

.big-title {
  position: relative;
  width: 100%;
  text-align: center;
  font-family: $fontTitle;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 0 50px;

  @media (max-width: 650px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.5px;
    margin-bottom: 25px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    width: 100%;
    height: 1px;
    background-color: rgba(#262626, .2);
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background-color: $colorAccent1;

    @media (max-width: 650px) {
      height: 2px;
      bottom: -10px;
    }
  }

  span {
    position: relative;
    z-index: 2;
    padding: 0 15px;
    background-color: $grey;
    &.white-bg {
      background-color: $white;
    }
  }
}

.centered-text {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: .5px;
  font-weight: 500;
  margin: -20px 0 60px;

  @media (max-width: 650px) {
    margin: 0 0 40px;
  }
}

.hero-title {
  position: relative;
  width: 100%;
  text-align: left;
  font-family: $fontTitle;
  font-size: 49px;
  line-height: 49px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  color: $white;

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 36px;
  }

  @media (max-width: 525px) {
    font-size: 30px;
    line-height: 30px;
  }
}

.medium-title {
  font-family: $fontTitleSerif;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.54px;
  line-height: 32px;
  color: $colorAccent1;
  letter-spacing: 0.055em;
}

.medium-title.with-child {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  span {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;

    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
}

.small-title {
  font-family: $fontTitle;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.89px;
  line-height: 22px;
  color: $colorAccent1;

  @media screen and (min-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
}

// --------------------------------------------------
// Text
// --------------------------------------------------

.regular-text {
  font-family: $fontTextLight;
  font-size: 16px;
  letter-spacing: -0.055px;
  line-height: 1.5;
  color: $dark;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
    color: $dark;
  }

  a {
    color: $colorAccent3;
    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent1;
    }
  }

  &.is-centered {
    width: 100%;
    text-align: center;
    letter-spacing: 0.035em;
    font-size: 18px;
  }
}

.hero-subtitle {
  font-size: 22px;
  padding-left: 0;
  margin: 0;
  font-weight: 400;
  line-height: 30px;
  color: $white;
  @extend .regular-text;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: 525px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.text-input {
  font-family: $fontTextLight;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 22px;
  color: $dark;

  @media screen and (min-width: 1440px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.small-text {
  font-family: $fontTitle;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 17px;
  color: $dark;

  @media screen and (min-width: 1440px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.links {
  font-family: $fontTitle;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 14px;
  color: $colorAccent2;

  @media screen and (min-width: 1440px) {
    font-size: 24px;
    line-height: 16px;
  }
}

.button-text {
  font-family: $fontTitle;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.27px;
  line-height: 11px;

  @media screen and (min-width: 1440px) {
    font-size: 13px;
    line-height: 13px;
  }
}

.pagination {
  font-family: $fontTitle;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: $dark;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// --------------------------------------------------
// Extra
// --------------------------------------------------

.light-typo {
  color: #fff;
}

.lightblue-typo {
  color: $colorAccent2;
}

.centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.separator-content-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.is-separator {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -75px;
    transform: translate3d(-100%, -50%, 0);
    backface-visibility: hidden;
    filter: blur(0);
    width: 1000%;
    height: 1px;
    background: #fff;

    @media only screen and (max-width: 699px) {
      left: -45px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -75px;
    transform: translate3d(100%, -50%, 0);
    backface-visibility: hidden;
    filter: blur(0);
    width: 1000%;
    height: 1px;
    background: #fff;

    @media only screen and (max-width: 699px) {
      right: -45px;
    }
  }
}


ul {
  li {
    line-height: 2;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      line-height: 2;
    }
  }
}
