.home-hero-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;

  .social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 70px;

    a {
      font-size: 24px;
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media(max-width: 900px) {
      display: none;
    }
  }

  .home-hero {
    width: calc(100% - 70px);
    display: flex;
    height: 80vh;

    @media(max-width: 1200px) {
      flex-direction: column;
      height: auto;
    }

    @media(max-width: 900px) {
      width: 100%;
    }
  }

  .home-slider {
    flex-grow: 1;
    height: 100%;
    border-right: 1px solid white;

    @media(max-width: 1200px) {
      height: 450px;
      border-right: none;
      border-bottom: 1px solid white;
    }

    .flickity-button {
      &.flickity-prev-next-button {
        background-color: transparent;
        border-radius: 0;
        width: 30px;
        height: 30px;

        svg {
          display: none;
        }

        &.previous {
          left: 40px;
          background: url('/img/icon-arrow-left-light.svg') no-repeat center center / contain;

          @media(max-width: 767px) {
            left: 20px;
          }
        }

        &.next {
          right: 40px;
          background: url('/img/icon-arrow-right-light.svg') no-repeat center center / contain;

          @media(max-width: 767px) {
            right: 20px;
          }
        }
      }
    }
  }

  .home-slider-item {
    width: 100%;
    position: relative;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 160px;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#000000', GradientType=0); /* IE6-9 */
    }
  }

  .item-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 40px;

    @media(max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
    }

    .item-infos {
      display: flex;
      flex-direction: column;
      max-width: 900px;

      .item-title {
        font-family: $fontTitle;
        font-size: 22px;
        line-height: 32px;
        font-weight: 600;
        letter-spacing: 0;
        text-transform: uppercase;
        color: white;
        margin: 0;

        @media(max-width: 767px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .item-subtitle {
        font-family: $fontTitle;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        letter-spacing: .89px;
        text-transform: uppercase;
        color: white;
        margin: 0;

        @media(max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }

    .site-btn {
      @media(max-width: 767px) {
        margin-top: 10px;
      }
    }
  }

  .home-gallery {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media(max-width: 1200px) {
      flex-direction: row;
      width: 100%;
      height: 240px;
    }

    @media(max-width: 1000px) {
      flex-direction: column;
      height: 600px;

      &.two-children {
        height: 400px;
      }
    }

    &.two-children .home-gallery-item {
      width: 100%;
      height: 50%;

      @media(max-width: 1200px) {
        width: 50%;
        height: auto;
      }

      @media(max-width: 1000px) {
        width: 100%;
        height: 50%;
      }
    }

    &.three-children .home-gallery-item {
      width: 100%;
      height: 33.33334%;

      @media(max-width: 1200px) {
        width: 33.33334%;
        height: auto;
      }

      @media(max-width: 1000px) {
        width: 100%;
        height: 33.33334%;
      }
    }
  }

  .home-gallery-item {
    position: relative;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    transition: all ease-out .2s;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(max-width: 1200px) {
      border-bottom: none;
      border-right: 1px solid white;
    }

    @media(max-width: 1024px) {
      justify-content: center;
    }

    @media(max-width: 1000px) {
      border-right: none;
      border-bottom: 1px solid white;
    }

    @media(max-width: 767px) {
      padding: 20px;
    }

    &:last-child {
      border-bottom: none;

      @media(max-width: 1200px) {
        border-right: none;
      }

      @media(max-width: 1000px) {
        border-bottom: none;
      }
    }

    &:hover {
      justify-content: center;

      &:before {
        height: 100%;
      }

      .item-title {
        transform: translateY(0);
        padding-bottom: 0;
      }

      .site-btn {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60%;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#000000', GradientType=0); /* IE6-9 */
      transition: all ease-out .2s;

      @media(max-width: 1000px) {
        height: 100%;
      }
    }

    .item-title {
      position: relative;
      text-align: center;
      font-family: $fontTitle;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      letter-spacing: .46px;
      color: white;
      text-transform: uppercase;
      margin: 0;
      transform: translateY(110px);
      padding-bottom: 50px;
      transition: all ease-out .3s;

      @media(max-width: 1024px) {
        transform: translateY(0);
        padding-bottom: 0;
      }
    }

    .site-btn {
      position: relative;
      min-width: 260px;
      margin-top: 10px;
      transform: translateY(110px);
      opacity: 0;
      transition: all ease-out .5s;

      @media(max-width: 1024px) {
        display: block;
        transform: translateY(0);
        opacity: 1;
      }

      @media(max-width: 767px) {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}
