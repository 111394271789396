.hero-wrapper {
  display: flex;
  align-items: stretch;

  .hero-caption {
    min-height: 500px;
    width: calc(100% - 70px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1200px) {
      min-height: 350px;
    }

    @media(max-width: 900px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      min-height: 200px;
      padding: 40px 20px;
    }

    &.small {
      min-height: 250px;

      @media (max-width: 768px) {
        min-height: 100px;
      }
    }

    &.show {
      position: relative;
      justify-content: flex-end;
      min-height: 650px;

      @media (max-width: 1200px) {
        min-height: 350px;
      }

      @media (max-width: 400px) {
        min-height: 200px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60%;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
      }

      .hero-title {
        position: relative;
        width: calc(100% - 500px);

        @media(max-width: 1100px) {
          width: 100%;
        }
      }
    }

    .hero-title {
      font-family: $fontTitle;
      font-size: 42px;
      line-height: 42px;
      letter-spacing: 0;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 22px;
      }
    }

    .site-btn {
      width: auto;
      max-width: 300px;
      margin: 20px 0 0;

      @media (max-width: 630px) {
        max-width: none;
      }
    }
  }

  .hero-subtitle {
    font-family: $fontTitle;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: .89px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    margin: 15px 0 0;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .hero-suptitle {
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 3px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    margin: 0 0 20px;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
      margin: 0 0 10px;
    }
  }

  .social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 70px;

    a {
      font-size: 24px;
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media(max-width: 900px) {
      display: none;
    }
  }
}
