.site-header {
  position: relative;

  @media (max-width: 900px) {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
  }

  .header-logo {
    position: absolute;
    background-color: white;
    padding: 30px;
    width: 350px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 54px 0 rgba(black, .3);
    z-index: 1;

    @media (max-width: 1200px) {
      width: 200px;
      height: 70px;
      padding: 0 20px;
      box-shadow: none;
    }

    img {
      width: 100%;
    }
  }

  .top-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    background-color: white;

    .language-link {
      width: 70px;
      text-align: center;
      font-family: $fontTitle;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: .88px;

      @media (max-width: 900px) {
        width: auto;
      }
    }

    .header-search {
      display: flex;
      width: 100%;
      max-width: 350px;
      margin-left: 20px;

      @media (max-width: 900px) {
        display: none;
      }

      input[type=search] {
        flex-grow: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: $fontTitle;
        font-style: italic;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: .17px;
        padding: 14px 20px 10px;
        border: 1px solid #C4C4C4;
        border-right: none;
        outline: none;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #6D6D6D;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #6D6D6D;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #6D6D6D;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #6D6D6D;
        }
      }

      button[type=submit] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 20px;
        line-height: 21px;
        padding: 12px 20px 12px 0;
        border: 1px solid #C4C4C4;
        border-left: none;
        outline: none;
        background-color: white;
      }
    }

    .top-text {
      font-family: $fontTitle;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: .2px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;

      @media (max-width: 900px) {
        display: none;
      }

      span {
        color: $colorAccent1;
      }
    }

    .mobile-menu-triggers {
      display: none;

      @media (max-width: 900px) {
        display: block;
        padding: 20px;
        cursor: pointer;
      }

      img {
        height: 20px;
        display: none;

        &.is-active {
          display: block;
        }
      }
    }
  }

  .main-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: black;
    width: calc(100% - 70px);
    padding: 0 60px;

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 1400px) {
      padding: 0 20px;
    }

    .nav-link {
      font-family: $fontTitle;
      font-weight: 600;
      font-size: 14px;
      line-height: 70px;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: white;
      margin-left: 50px;
      transition: all ease-out .2s;

      @media (max-width: 1400px) {
        margin-left: 20px;
      }

      &:hover, &.is-active {
        -webkit-box-shadow: inset 0px -5px 0px 0px $colorAccent1;
        -moz-box-shadow: inset 0px -5px 0px 0px $colorAccent1;
        box-shadow: inset 0px -5px 0px 0px $colorAccent1;
      }
    }
  }

  .mobile-menu {
    position: fixed;
    opacity: 0;
    transform: translateX(100%);
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
    background-color: $dark;
    transition: all ease-out .5s;

    &.is-open {
      opacity: 1;
      transform: none;
    }

    .mobile-menu-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .nav-link {
      font-family: $fontTitle;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: white;
      display: block;
      width: 100%;
      margin: 0;
      padding: 15px 20px;
      background-color: black;
      border-bottom: 1px solid #6D6D6D;
    }

    .header-search {
      display: flex;
      width: 100%;
      margin: 0;

      input[type=search] {
        flex-grow: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: $fontTitle;
        font-style: italic;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: .17px;
        padding: 14px 20px 10px;
        border: none;
        outline: none;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #6D6D6D;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #6D6D6D;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #6D6D6D;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #6D6D6D;
        }
      }

      button[type=submit] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 20px;
        line-height: 21px;
        padding: 12px 20px 12px 0;
        border: none;
        outline: none;
        background-color: white;
      }
    }

    .social-links {
      margin-top: 30px;
      font-size: 20px;
      color: white;
    }
  }
}
