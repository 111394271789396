.partners-cta {
  background-color: $grey;
  padding: 80px 20px;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .partners-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;

    &.page {
      .partners-list {
        &:not(.big) {
          margin-bottom: 40px;
        }
        .partial-partner {
          margin-bottom: 40px;
        }
      }
    }
  }

  .partners-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 1100px) {
      flex-wrap: wrap;
    }

    @media (max-width: 650px) {
      flex-direction: column;
    }

    &.big {
      .partial-partner {
        width: calc(50% - 10px);
        min-height: 250px;

        @media (max-width: 1100px) {
          width: calc(50% - 10px);
          margin-bottom: 20px;
          min-height: 170px;
        }

        @media (max-width: 650px) {
          width: 100%;
          min-height: 140px;
        }
      }
    }
  }

  .partial-partner {
    width: calc(25% - ((20px * 3) / 4));
    height: 170px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 1100px) {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }

    @media (max-width: 650px) {
      width: 100%;
      min-height: 140px;
    }

    img {
      width: 70%;
      max-width: 70%;
    }
  }

  .site-btn {
    margin-top: 50px;

    @media (max-width: 1100px) {
      margin-top: 30px;
    }
  }
}
