.show-details-wrapper {
  width: calc(100% - 70px);
  position: relative;
  display: flex;
  align-items: stretch;
  margin-top: -100px;

  @media(max-width: 1100px) {
    margin-top: 0;
  }

  @media(max-width: 900px) {
    width: 100%;
    flex-direction: column-reverse;
  }

  .show-detail-main {
    width: calc(100% - 450px);
    padding: 170px 70px 70px;
    font-family: $fontTitle;

    @media(max-width: 1100px) {
      padding: 40px;
    }

    @media(max-width: 900px) {
      width: 100%;
      padding: 40px 20px 20px;
    }

    p {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 500;
      margin: 0 0 30px;
    }

    h1 {
      font-size: 20px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 0 10px;
    }

    h2, h3, h4, h5, h6 {
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 0 10px;
    }

    ul,ol {
      li {
        font-size: 14px;
        line-height: 30px;
        letter-spacing: .2px;
        color: #6D6D6D;
        font-weight: 500;
      }
    }

    figure {
      margin: 0 0 40px;

      @media(max-width: 900px) {
        margin-bottom: 20px;
      }
    }

    img {
      max-width: 100%;
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .show-detail-aside {
    width: 100%;
    max-width: 450px;
    background-color: $dark;
    color: white;
    padding: 70px 50px;
    font-family: $fontTitle;

    @media(max-width: 1100px) {
      padding: 40px;
    }

    @media(max-width: 900px) {
      max-width: none;
      padding: 40px 20px;
    }

    .show-date {
      font-size: 22px;
      line-height: 22px;
      letter-spacing: 1.57px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0 0 40px;

      @media(max-width: 900px) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .show-buy-title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      letter-spacing: .9px;
      text-transform: uppercase;
      margin: 0 0 10px;

      @media(max-width: 900px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .site-btn {
      width: 100%;
    }

    .button-note {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      letter-spacing: .17px;
      color: white;
      margin: 10px 0 0;

      @media(max-width: 900px) {
        font-size: 10px;
        line-height: 18px;
      }
    }

    .show-room {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      letter-spacing: 1.12px;
      color: #929292;
      text-transform: uppercase;
      margin: 50px 0 10px;

      @media(max-width: 900px) {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .show-payments-methods {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: .2px;
      margin: 0;

      @media(max-width: 900px) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .show-conditions {
      font-size: 12px;
      line-height: 22px;
      font-weight: 400;
      letter-spacing: .17px;
      color: #929292;
      padding-top: 30px;
      border-top: 1px solid rgba(white, .4);
      margin: 30px 0 0;

      @media(max-width: 900px) {
        font-size: 10px;
        line-height: 18px;
      }
    }
  }
}
