.overlay-form {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, .35);

  z-index: -1;

  opacity: 0;
  transform: scale(1);

  transition: all 0.35s ease;

  &.is-active {
    z-index: 45;

    opacity: 1;
    transform: scale(1);
  }
}


.overlay-form-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(.95);

  width: 100%;
  max-width: 500px;
  min-width: 375px;
  padding: 45px 75px;

  background: #fff;

  z-index: -1;

  opacity: 0;

  transition: all 0.35s ease;

  @media only screen and (max-width: 767px) {
    padding: 25px;
    height: 100vh;

    overflow-y: auto;
  }

  &.is-active {
    z-index: 50;

    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }

  .overlay-close {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .overlay-form-headings {
    text-align: center;

    .medium-title {
      margin-bottom: 0;
    }

    .small-title {
      margin: 0 0 25px 0;
    }
  }

  .form-cols {
    flex-direction: column;
    .form-col-half {
      width: 100%;
      padding: 0;
    }
  }
}
