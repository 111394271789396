.page-subnav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 60px;
  margin-bottom: 80px;
  height: 70px;
  width: calc(100% - 70px);
  background-color: $dark;

  @media (max-width: 1023px) {
    padding: 28px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  &:not(.share) {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &.for-stay {
    @media (max-width: 767px) {
      height: auto;
      padding: 10px 0;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;

      &.share {
        flex-direction: row;
        padding: 10px 20px;
        margin-bottom: 20px;

        .nav-link {
          padding-left: 0;
        }
      }

      .nav-link {
        line-height: 30px;
        margin-left: 0;
        padding-left: 20px;

        &.is-active {
          -webkit-box-shadow: inset 5px 0px 0px 0px $colorAccent1;
          -moz-box-shadow: inset 5px 0px 0px 0px $colorAccent1;
          box-shadow: inset 5px 0px 0px 0px $colorAccent1;
        }
      }
    }
  }

  &.share {
    margin-bottom: 0;
  }

  .nav-link {
    font-family: $fontTitle;
    font-weight: 600;
    font-size: 14px;
    line-height: 70px;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: white;
    margin-left: 50px;

    @media (max-width: 1400px) {
      margin-left: 20px;
    }

    &.is-active {
      -webkit-box-shadow: inset 0px -5px 0px 0px $colorAccent1;
      -moz-box-shadow: inset 0px -5px 0px 0px $colorAccent1;
      box-shadow: inset 0px -5px 0px 0px $colorAccent1;
    }

    &.icon {
      margin-left: 20px;
      font-size: 20px;
      transition: color ease-out .2s;

      &:hover {
        color: $colorAccent1;
      }
    }
  }
}

.virtual-block {
  max-width: 1240px;
  margin: 80px auto;
}

.related-articles {
  background: $grey;
  margin: 0 auto;
  padding: 80px 40px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 40px;
  }

  .articles-container {
    max-width: 1240px;
    margin: auto;
  }

  .articles-holder {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 0 auto 45px;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
}
