.timeline-block {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	max-width: 1080px;

	.timeline-title {
		color: #262626;
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 600;
		margin: 0 0 74px;
	}

	.timeline-wrapper {
		position: relative;
		width: 100%;

		.line-content {
			position: relative;

			display: flex;
			justify-content: center;
			align-items: center;

			padding: 40px 0;

			&:nth-child(even) {
				flex-direction: row-reverse;

				.band {
					left: 0;
				}
			}

			&:nth-child(odd) {
				.text-content {
					text-align: right;
				}
			}

			&:nth-child(-n+2) {
				.band {
					height: 50%;
				}
			}

			&:nth-child(n+3) {
				.band {
					height: 65%;
				}
			}

			&:nth-child(2n+5) {
				.band {
					height: 75%;
				}
			}

			.medium-title {
				color: black;
				text-transform: uppercase;
				font-size: 18px;
				line-height:18px;
				font-weight: 600;
				font-family: $fontTitle;
				color: $dark;
				margin: 25px 0 14px;
			}

			.text-content, .image-content {
				flex: 1;
			}

			.text-content {
				font-family: $fontTextLight;
				color: #6d6d6d;
				line-height: 24px;
				font-weight: 400;
			}

			.image-content {
				position: relative;

				img {
					width: 100%;
				}
			}

			.year {
				margin: auto 50px;
				padding: 29px 16px 25px 16px;
				border-radius: 50%;
				background: $dark;
				color: $white;
				font-weight: 600;
				font-family: $fontText;
				line-height: 1;

				z-index: 1;

			}

			.band {
				position: absolute;
				right: 0;

				width: 10px;
				background: rgba(212,19,23, 0.7);
			}

			&:after {
				content: '';
				position: absolute;
				width: 6px;
				background-color: $grey;
				left: 50%;
				margin-left: -3px;
				height: 100%;
				z-index: -1;
			}

			&:first-child:after {
				top: 50%;
			}

			&:last-child:after {
				bottom: 50%;
			}
		}

		@media (max-width: 768px) {
			.line-content {
				justify-content: flex-start;
				flex-direction: column;

				padding: 5px 0;

				.year {
					position: absolute;
					left: 0;

					font-size: 12px;

					padding: 19.5px 11px 17.5px 11px;
					margin: 28px 0 0 0;
				}

				.text-content, .image-content {
					margin-left: 60px;
				}

				&:nth-child(even) {
					flex-direction: column
				}

				&:nth-child(odd) {
					.text-content {
						text-align: left;
					}
				}

				&:after {
					content: '';
					position: absolute;
					width: 6px;
					background-color: $grey;
					left: 25px;
					margin-left: -3px;
					height: 100%;
				}

				&:first-child:after {
					top: 10%;
				}

				&:last-child:after {
					bottom: 90%;
				}
			}
		}

		@media (min-width: 375px) and (max-width: 768px) {
			.line-content {
				.text-content {
					padding-top: 15px;
				}
			}
		}

	}
}

@media (max-width: 768px) {
	.timeline-block {
		padding: 0 20px;
	}
}
