// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn {
  display: inline-block;
  font-family: $fontTitle;
  padding: 15px 20px 12px;

  background: $colorAccent1;
  border: 2px solid $colorAccent1;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: .5px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  -webkit-appearance: none;
  outline: none;

  @media (max-width: 620px) {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
  }

  &:hover {
    background-color: transparent;
    color: white;
  }

  &.is-outlined {
    background: transparent;
    border: 2px solid $colorAccent1;
    color: white;

    &.dark-outlined {
      border-color: $dark;
      color: $dark;

      &:hover {
        background: $dark;
        color: $white;
      }
    }

    &:hover {
      background-color: $colorAccent1;
    }

    &.is-dark {
      color: black;
      border-color: black;

      &:hover {
        color: white;
        background-color: black;
      }
    }
  }
}

.link-text-btn {
  font-family: $fontTitleSerif;
  text-transform: uppercase;
  color: $colorAccent3;
  padding: 15px 0;
  padding-right: 45px;
  border-bottom: 3px solid $colorAccent3;
}
