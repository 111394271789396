// --------------------------------------------------
// Block
// --------------------------------------------------

.site-block {
  margin: 80px auto;
  padding: 0 30px;

  &.fullwidth-block {
    padding: 0;
  }

  @media (max-width: 767px) {
    margin: 40px auto;
  }
}

// --------------------------------------------------
// Section Hero
// --------------------------------------------------

.block-section-hero {
  position: relative;
  width: 100%;
  height: 27vw;
  padding: 100px;
  background: #434A98;
  background-image: url('/img/site/ph1-hero.jpg');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #444B99;
    z-index: 1;
    opacity: .90;
  }

  @media (max-width: 1099px) {
    padding: 20px;
  }

  @media (max-width: 699px) {
    height: 125px;
  }

  .separator-content-wrapper {
    height: 100%;
  }

  .block-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin: 0;
    font-family: $fontTitleSerif;
    color: #fff;
    text-align: center;
    letter-spacing: 0.055em;
    z-index: 2;

    @media (max-width: 767px) {
      font-size: 14px;
      min-width: 200px;
    }
  }
}

// --------------------------------------------------
// Block Introduction
// --------------------------------------------------

.block-introduction {
  display: flex;
  padding: 0 0 0 0;
  border-bottom: 1px solid $lighter;
  background: url('/img/site/intro-ph.png');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;

  .block-content {
    width: 100%;
    padding: 75px;
    background: rgba(255, 255, 255, 0.80);

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }
  }

  .big-title {
    margin-bottom: 15px;
  }

  .column {
    padding-right: 35px;

    &.last {
      padding-right: 0;
    }
  }

  .regular-text {

    strong {
      font-family: $fontTitleSerif;
      font-size: 18px;
      color: $colorAccent3;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 767px) {
      font-size: 14px;

      ul,
      ol {
        padding: 0 15px;
        margin: 0;
      }
    }
  }
}

// --------------------------------------------------
// Split text image
// --------------------------------------------------

.block-split-text-image {
  display: flex;
  padding: 0 0 0 0;
  border-bottom: 1px solid $lighter;

  @media only screen and (max-width: 599px) {
    flex-wrap: wrap;
  }

  &.is-left {
    flex-direction: row-reverse;
  }

  .block-content {
    position: relative;
    width: 75%;
    padding: 75px;

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    &.is-collapsed {
      &:after {
        content: '';
        position: absolute;
        top: 65%;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
        z-index: 2;
      }
    }

    .regular-text {
      // margin-bottom: 75px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    h2 {
      @extend .big-title;
      //color: $dark;
      line-height: 1.4;

      @media only screen and (max-width: 767px) {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
    }

    h3 {
      @extend .big-title;
      color: $dark;
      font-size: 26px;
      line-height: 1.4;
    }

    h4 {
      @extend .big-title;
      color: $dark;
      font-size: 20px;
      line-height: 1.4;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    figure {
      padding: 0;
      margin: 0;
    }

    blockquote {
      padding: 0 0 0 25px;
      margin: 25px 0;
      border-left: 4px solid $lighter;
    }

    .read-more {
      display: none;
      position: absolute;
      right: 75px;
      bottom: 25px;
      z-index: 3;
      padding: 5px 0;
      font-size: 14px;
    }
  }

  .block-image {
    width: 25%;

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    img {
      display: block;
    }
  }
}

// --------------------------------------------------
// Block - Image
// --------------------------------------------------

.block-image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// --------------------------------------------------
// Block - Grid
// --------------------------------------------------

.block-grid {
  display: flex;
  padding: 60px 20px 0;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1240px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding: 45px 20px;
  }

  .grid-text {
    padding: 45px 75px;

    @media only screen and (max-width: 767px) {
      padding: 45px 20px;
    }
  }

  .grid-items {
    display: flex;
    width: 100%;
    padding: 0 20px;
    margin-top: 35px;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: center;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0 20px;
    }
  }

  .grid-item {
    width: 33%;
    padding: 10px 15px;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 0 0 20px 0;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    .icon-holder {
      display: block;
      height: 90px;
      width: 90px;
      margin: 0 auto 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .small-title {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1px;
      font-weight: 600;
      color: $dark;
      text-transform: uppercase;
      margin: 0 0 15px;
    }

    .regular-text p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      font-weight: 400;
      color: #6D6D6D;
      margin: 0;
    }
  }
}

// --------------------------------------------------
// Posts
// --------------------------------------------------

.posts-list {
  width: 100%;
  padding: 75px 55px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 1349px) {
    padding: 75px 45px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 75px 25px;
  }

  @media only screen and (max-width: 767px) {
    padding: 25px 20px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    &:before,
    &:after {
      background: $grey;
    }

    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}

// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  a,
  div {
    display: block;
    height: 35px;
    padding: 10px 15px;

    border-top: 1px $light solid;
    border-bottom: 1px $light solid;
    border-left: 1px $light solid;

    background: #fff;
    color: $dark;
    text-decoration: none;

    @extend .button-text;

    &.content-pagination-prev,
    &.content-pagination-next {
      span {
        display: block;
        width: 0;
        height: 0;
        border-top: 6.2px solid transparent;
        border-bottom: 6.2px solid transparent;
      }
    }

    &.content-pagination-prev {
      border-radius: 3px 0 0 3px;

      span {
        border-right: 6.2px solid $colorAccent1;

        &.is-disabled {
          border-right-color: $light;
        }
      }
    }

    &.content-pagination-next {
      border-right: 1px $light solid;
      border-radius: 0 3px 3px 0;

      span {
        border-left: 6.2px solid $colorAccent1;

        &.is-disabled {
          border-left-color: $light;
        }
      }
    }

    &:hover {
      color: $colorAccent1;
    }

    &.is-current {
      background: $colorAccent3;
      color: #fff;
      border-color: $colorAccent3;
    }

    &:last-child {
      border-right: 1px $light solid;
    }
  }
}

.article-details {
  padding: 75px;

  @media (max-width: 767px) {
    padding: 35px 20px 0 20px;
  }

  .article-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px 0;
    border-bottom: 4px solid $lighter;
  }

  .block-content {
    padding-top: 0;
  }

  .block-split-text-image {

    .block-content {
      width: 60%;

      &.is-full {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
      }
    }

    .block-image {
      width: 40%;
    }
  }
}

.side-social-box {
  color: $grey;
  text-align: center;
  padding: 0 20px 20px 20px;

  h3 {
    padding: 20px 0;
    font-weight: 400;
    border-bottom: 1px solid $grey;
  }

  .socials {
    text-align: center;

    a {
      color: $colorAccent1;
      font-size: 25px;
      margin: 0 5px;
      border: none;
    }
  }
}

.member-profile-box {
  padding: 25px 55px;
  text-align: center;
  border-bottom: 2px solid $alternateWhite;

  .member-profile-img {
    display: inline-block;
    width: 85px;
    height: auto;
    border-radius: 50%;
  }

  .member-profile-header {
    font-family: $fontTextLight;
    color: $colorAccent1;
    font-size: 14px;
  }

  .member-profile-text {
    font-family: $fontTextLight;
    color: $grey;
    font-size: 14px;
  }

  .member-profile-link {
    display: inline-block;
    width: 100%;
    font-family: $fontTextLight;
    color: $colorAccent3;
    border: none;
    font-size: 14px;

    strong {
      font-family: $fontText;
    }
  }
}

// --------------------------------------------------
// Block - Gallery Text
// --------------------------------------------------
.block-gallery-text {
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  &.reversed-block {
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .gallery-wrapper {
      &:before {
        right: 0;
        background: rgba(212, 19, 23, 0.52);
      }

      &:after {
        left: auto;
        right: 70px;
        background: rgba(0, 0, 0, 0.52);
      }

      .flickity-button {
        &.previous {
          left: 0;
        }

        &.next {
          left: 75px;
        }
      }
    }
  }

  .gallery-wrapper {
    position: relative;
    width: 60%;

    @media (max-width: 900px) {
      width: 100%;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;

      display: block;

      pointer-events: none;
      z-index: 3;
    }

    &:before {
      width: 70px;
      height: 100%;
      background: rgba(0, 0, 0, 0.52);
    }

    &:after {
      left: 70px;
      width: 35px;
      height: 50%;
      background: rgba(212, 19, 23, 0.52);
    }

    img {
      width: 100%;
      vertical-align: middle;
    }
  }

  .flickity-button {
    bottom: 0;
    top: auto;

    background: $white;
    border-radius: 0;
    transform: none;

    &.previous {
      padding: 40px 25px 40px 50px;
      right: 75px;
      left: auto;

      @media (min-width: 768px) and (max-width: 1150px) {
        padding: 30px 25px 30px 50px;
      }
    }

    &.next {
      padding: 40px 50px 40px 25px;
      right: 0;

      &:before {
        transform: rotate(-180deg);
      }

      @media (min-width: 768px) and (max-width: 1150px) {
        padding: 30px 25px 30px 50px;
      }
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      margin: auto;
      width: 20px;
      height: 16px;

      background: url('../img/site/arrow-left.svg') no-repeat;
      background-size: contain;
    }

    &[disabled] {
      display: none;
    }

    svg {
      display: none;
    }
  }

  .gallery-content {
    padding: 35px 70px;
    width: 40%;

    @media (max-width: 1023px) {
      padding: 40px;
    }

    @media (max-width: 900px) {
      width: 100%;
      padding: 40px 20px;
    }

    .medium-title {
      color: $dark;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0 0 40px;
      font-weight: 600;
    }

    .capacity-text {
      @extend .small-title;
      color: $dark;
      text-transform: uppercase;
      font-weight: 600;

      .icon-people {
        margin-right: 10px;
        color: $light;
      }
    }

    .reading-text {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 400;

      a {
        color: $colorAccent1;
      }
    }

    .site-btn {
      margin-top: 50px;
    }
  }
}

.ice-iframe {
  width: 100%;
  padding: 40px 20px;
  max-width: 1520px;
  margin: 0 auto;

  iframe {
    border: 0;
    width: 100%;
    height: 1250px;
  }
}