.site-newsletter {
  background-color: $dark;
  padding: 80px 20px;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .newsletter-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .newsletter-content {
    //width: 450px;
    width: 100%;
    text-align: center;

    @media (max-width: 1000px) {
      width: 100%;
      text-align: center;
    }
  }

  .newsletter-title {
    font-family: $fontTitle;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    margin: 0 0 15px;

    @media (max-width: 550px) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .newsletter-text {
    font-family: $fontTitle;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .2px;
    color: white;
    margin: 0 auto 20px;
    max-width: 500px;

    @media (max-width: 1000px) {
      max-width: 100%;
    }
  }

  //.newsletter-form {
  //  flex-grow: 1;
  //  display: flex;
  //  align-items: center;
  //
  //  @media (max-width: 1000px) {
  //    width: 100%;
  //    max-width: 600px;
  //  }
  //
  //  input[type=email] {
  //    flex-grow: 1;
  //    font-family: $fontTitle;
  //    font-weight: 400;
  //    font-size: 14px;
  //    line-height: 24px;
  //    letter-spacing: 0;
  //    padding: 18px 25px;
  //    background-color: white;
  //    border-top-left-radius: 4px;
  //    border-bottom-left-radius: 4px;
  //    -webkit-appearance: none;
  //    -moz-appearance: none;
  //    appearance: none;
  //    border: none;
  //    outline: none;
  //
  //    @media (max-width: 550px) {
  //      padding: 10px 20px;
  //      font-size: 10px;
  //      border-top-left-radius: 2px;
  //      border-bottom-left-radius: 2px;
  //    }
  //  }
  //
  //  button[type=submit] {
  //    font-family: $fontTitle;
  //    font-weight: 600;
  //    font-size: 14px;
  //    line-height: 24px;
  //    letter-spacing: 0;
  //    padding: 20px 50px 16px;
  //    text-transform: uppercase;
  //    color: white;
  //    background-color: $colorAccent1;
  //    border-top-right-radius: 4px;
  //    border-bottom-right-radius: 4px;
  //    -webkit-appearance: none;
  //    -moz-appearance: none;
  //    appearance: none;
  //    border: none;
  //    outline: none;
  //    cursor: pointer;
  //
  //    @media (max-width: 550px) {
  //      padding: 10px 20px;
  //      font-size: 10px;
  //      border-top-right-radius: 2px;
  //      border-bottom-right-radius: 2px;
  //    }
  //  }
  //}
}