.shows-list-container {
  padding: 0 20px 70px;
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.search {
    padding-top: 70px;

    @media(max-width: 900px) {
      padding-top: 40px;
    }
  }

  .big-title {
    margin: 80px 0 60px;

    @media(max-width: 900px) {
     margin: 40px 0 40px;
    }
  }
}

.shows-list {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.show-partial {
  width: calc(33.33334% - (40px / 3));
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &:nth-child(3n+3) {
    margin-right: 0;
  }

  @media(max-width: 900px) {
    width: calc(50% - 10px);

    &:nth-child(3n+3) {
      margin-right: 20px;
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }

  @media(max-width: 650px) {
    width: 100%;
    margin-right: 0;
  }

  .show-card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .show-image {
    width: 100%;
    max-height: 300px;
    border-bottom: 1px solid white;
  }

  .show-infos {
    flex-grow: 1;
    padding: 40px 20px 25px;
    background-color: $dark;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .show-title {
    font-family: $fontTitle;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: .46px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 10px;
  }

  .show-date {
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .46px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
  }

  .show-tags {
    margin: 15px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .show-tag {
    font-family: $fontTitle;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
    font-weight: 400;
    padding: 6px 20px 4px;
    border: 1px solid white;
    border-radius: 3px;
    margin: 2.5px;
  }

  .show-link {
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .7px;
    font-weight: 600;
    padding: 17px 10px 13px;
    background-color: $colorAccent1;
    color: white;
    text-transform: uppercase;
    text-align: center;
  }
}
