.article-card {
  width: 33.33%;
  margin-right: 20px;

  @media (max-width: 767px) {
    width: 100%;
    margin: 20px 0;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    vertical-align: middle;
  }

  .article-content {
    background: $white;
    padding: 35px;

    p{
      margin: 0;
    }

    .article-date,
    .article-title {
      text-transform: uppercase;
      font-weight: 400;
    }

    .article-title {
      margin-top: 15px;
      color: $dark;
    }
  }

  .site-btn {
    width: 100%;
    margin: 0;
    background: $dark;
    color: $white;
    border: none;
  }
}
