.blog-content-wrapper {
  width: 100%;
  max-width: 840px;
  padding: 90px 20px;
  margin: 0 auto;
  font-family: $fontTitle;

  @media(max-width: 900px) {
    padding: 40px 20px;
  }

  .blog-date {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .5px;
    color: $colorAccent1;
    font-weight: 600;
    text-align: center;
    margin: 0 0 40px;
    text-transform: uppercase;
  }

  .blog-content {
    a {
      color: $colorAccent1;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: .5px;
      color: #757C8B;
      font-weight: 400;
      margin: 0 0 30px;
    }

    strong {
      font-weight: 700;
    }

    h1, h2, h3, h4, h5, h6 {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0 0 20px;
      color: #3F3F3F;

      @media(max-width: 900px) {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: .5px;
      }
    }

    ul {
      list-style: none;
      margin: 0 0 30px;
      padding: 0;

      li {
        font-size: 14px;
        line-height: 30px;
        letter-spacing: .5px;
        color: #757C8B;
        font-weight: 400;

        &:before {
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          text-align: center;
          /* opacity: .8; */
          font-variant: normal;
          text-transform: none;
          content: "\e802";
          margin-right: 20px;
          color: $colorAccent1;

          @media(max-width: 900px) {
            margin-right: 10px;
          }
        }
      }
    }

    figure {
      margin: 0 0 30px;
    }

    img {
      max-width: 100%;
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .blog-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
    padding-bottom: 40px;

    &:after {
      position: absolute;
      content: '';
      width: 60px;
      height: 4px;
      background-color: #EBEBEB;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .blog-intro-title {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
      text-align: center;

      @media(max-width: 900px) {
        font-size: 22px;
        line-height: 26px;
        letter-spacing: .5px;
      }
    }

    .blog-intro-subtitle {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .6px;
      color: #757C8B;
      font-weight: 400;
      margin: 20px 0 0;
      text-align: center;
    }
  }

  .blog-cta {
    display: flex;
    align-items: center;
    border-top: 2px solid #EBECEE;
    border-bottom: 2px solid #EBECEE;
    margin: 60px 0 60px;
    padding: 50px 0;

    @media(max-width: 700px) {
      flex-direction: column;
    }

    .blog-cta-title {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 1px;
      color: #3F3F3F;
      font-weight: 600;
      margin: 0 60px 0 0;
      text-transform: uppercase;
      width: 60%;

      @media(max-width: 700px) {
        width: 100%;
        margin: 0;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .site-btn {
      width: 40%;

      @media(max-width: 700px) {
        margin-top: 20px;
        width: 100%;
      }

      &:hover {
        color: $colorAccent1;
      }
    }
  }

  .blog-quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 60px;

    &:before {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      text-align: center;
      /* opacity: .8; */
      font-variant: normal;
      text-transform: none;
      content: "\e805";
      color: #EBEBEB;
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 25px;
    }

    .blog-quote-title {
      font-size: 34px;
      line-height: 38px;
      letter-spacing: -.2px;
      color: $colorAccent1;
      font-weight: 700;
      margin: 0 0 60px;
      text-align: center;

      @media(max-width: 900px) {
        font-size: 22px;
        line-height: 26px;
        letter-spacing: .5px;
        margin-bottom: 20px;
      }
    }

    .blog-quote-author {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: .88px;
      font-weight: 600;
      margin: 0;
      text-align: center;
    }

    .blog-quote-subtitle {
      font-size: 12px;
      line-height: 25px;
      letter-spacing: .2px;
      font-weight: 400;
      margin: 5px 0 0;
      text-align: center;
    }
  }

  .blog-author {
    margin: 0 0 60px;
    .big-title {
      margin-bottom: 60px;

      span {
        background-color: white;
      }
    }

    .blog-author-infos {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    .blog-author-avatar {
      width: 60px;
      margin-right: 20px;

      @media(max-width: 900px) {
        width: 40px;
      }
    }

    .blog-author-bio {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
      color: #8F9DAE;
      font-weight: 400;
      margin: 0;
    }

    .blog-author-name {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      letter-spacing: .9px;
      margin: 0;
      text-transform: uppercase;
    }

    .blog-author-job {
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0;
      margin: 5px 0 0;
      font-weight: 400;
      color: $colorAccent1;
    }
  }
}