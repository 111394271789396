.terms-content {
  width: 100%;
  max-width: 1280px;
  padding: 80px 20px;
  margin: 0 auto;
  font-family: $fontTitle;

  @media(max-width: 900px) {
    padding: 40px 20px;
  }

  p {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: .2px;
    color: #6D6D6D;
    font-weight: 500;
    margin: 0 0 30px;
  }

  h1 {
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 10px;
  }

  h2, h3, h4, h5, h6 {
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 10px;
  }

  figure {
    margin: 0 0 40px;

    @media(max-width: 900px) {
      margin-bottom: 20px;
    }
  }

  img {
    max-width: 100%;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}