// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitleSerif: 'itc', serif;
$fontTitle: 'itc', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontText: 'itc', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTextLight: 'itc', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Accents Colors
$colorAccent1: #D41317;
$colorAccent2: #B5B5EB;
$colorAccent3: #49A9D5;

// Blocks and Text
$dark: #262626;
$grey: #EBEBEB;
$light: #A0ACB9;
$lighter: #E5EAEF;
$alternateWhite: #f6f8fc;
$white : #fff;


// --------------------------------------------------
// Custom Fonts
// --------------------------------------------------

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 1, 2018 */
@font-face {
    font-family: 'itc';
    src: url('../font/ITCAvantGardeStd-Bk.woff2') format('woff2'),
         url('../font/ITCAvantGardeStd-Bk.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'itc';
    src: url('../font/ITCAvantGardeStd-Bold.woff2') format('woff2'),
         url('../font/ITCAvantGardeStd-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'itc';
    src: url('../font/ITCAvantGardeStd-Demi.woff2') format('woff2'),
         url('../font/ITCAvantGardeStd-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'itc';
    src: url('../font/ITCAvantGardeStd-XLt.woff2') format('woff2'),
         url('../font/ITCAvantGardeStd-XLt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
