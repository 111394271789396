.teasers {
  background-color: $grey;
  padding: 80px 20px;

  @media (max-width: 1100px) {
    padding: 80px 20px 60px;
  }

  @media (max-width: 767px) {
    padding: 40px 20px 20px;
  }

  .teasers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
  }

  .teasers-list {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1100px) {
      flex-direction: column;
    }
  }

  .teaser-item {
    position: relative;
    width: calc(50% - 10px);

    @media (max-width: 1100px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .teaser-image {
    width: 100%;
  }

  .teaser-infos {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    top: 0;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60%;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
      z-index: 2;

      @media (max-width: 550px) {
        height: 100%;
      }
    }
  }

  .teaser-infos-container {
    position: relative;
    display: flex;
    height: 100%;
    align-items: flex-end;
    z-index: 3;
    padding: 20px 30px;

    @media (max-width: 550px) {
      padding: 20px;
    }
  }

  .teaser-infos-container-inception {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .teaser-title {
    font-family: $fontTitle;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.5px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;

    @media (max-width: 550px) {
      margin-bottom: 10px;
    }
  }

  .site-btn {
    @media(max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
}
