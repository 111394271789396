.pagination {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 50px;
  font-family: $fontTitle;
  font-size: 14px;
  line-height: 14px;


  .current {
    text-align: center;
    padding: 17px 10px 13px;
    background-color: $colorAccent1;
    color: #fff;
    margin: 0 5px;
  }

  a {
    padding: 17px 10px 13px;
    text-decoration: none;
    margin: 0 5px;

    i {
      font-size: 18px;
      line-height: 18px;
    }
  }
}