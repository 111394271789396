.contact-information {
  display: flex;
  align-items: center;
  margin: 70px 0;

  @media(max-width: 700px) {
    flex-direction: column-reverse;
    margin: 40px 0;
  }

  #contact-map {
    position: relative;
    height: 630px;
    width: 60vw;

    @media(max-width: 900px) {
      height: 350px;
      width: 50vw;
    }

    @media(max-width: 700px) {
      width: 100%;
      height: 300px;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      width: 70px;
      background: rgba(black, .5);
      z-index: 1;

      @media(max-width: 900px) {
        width: 30px;
      }
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      height: 50%;
      left: 70px;
      width: 35px;
      background: rgba($colorAccent1, .5);
      z-index: 1;

      @media(max-width: 900px) {
        left: 30px;
        width: 15px;
      }
    }
  }

  .contact-map-wrapper {
    margin-left: 70px;

    @media(max-width: 700px) {
      width: 100%;
      margin-bottom: 40px;
      margin-left: 0;
      padding: 0 20px;
    }

    .contact-map-heading {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 1px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0 0 30px;
    }

    .contact-map-address {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 400;
      margin: 0 0 40px;
    }

    .contact-map-phone, .contact-map-email {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 400;
      margin: 0;

      a {
        color: $colorAccent1;
      }
    }

    .site-btn {
      width: 100%;
      max-width: 250px;
      text-align: center;
      margin: 40px 0 0;

      @media(max-width: 900px) {
        max-width: none;
      }
    }
  }
}

.contact-department-section {
  width: 100%;
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto 70px;

  @media(max-width: 700px) {
    margin-bottom: 20px;
  }

  .big-title {
    margin-bottom: 80px;

    @media(max-width: 700px) {
      margin-bottom: 40px;
    }
  }

  .contact-departments {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .contact-department {
    width: calc(33.33334% - (40px / 3));
    border: 1px solid #D7D7D7;
    margin-bottom: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;

    &:nth-child(even) {
      background-color: #F5F5F5;
    }

    @media(max-width: 1000px) {
      width: calc(50% - 10px);

      &:nth-child(even) {
        background-color: white;
      }

      &:nth-child(4n+2), &:nth-child(4n+3) {
        background-color: #F5F5F5;
      }
    }

    @media(max-width: 700px) {
      width: 100%;
      padding: 20px;

      &:nth-child(4n+2), &:nth-child(4n+3) {
        background-color: white;
      }

      &:nth-child(even) {
        background-color: #F5F5F5;
      }
    }
  }

  .department-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    font-weight: 600;
    margin: 0 0 5px;
    text-transform: uppercase;
  }

  .department-responsable {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .2px;
    font-weight: 400;
    margin: 0 0 60px;
    flex-grow: 1;

    @media(max-width: 700px) {
      margin-bottom: 30px;
    }
  }

  .department-phone {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .2px;
    font-weight: 400;
    margin: 0;
    color: $colorAccent1;
  }
}

.contact-form-wrapper {
  background-color: #EBEBEB;

  .contact-form {
    width: 100%;
    max-width: 1280px;
    padding: 70px 20px;
    margin: 0 auto;

    @media(max-width: 700px) {
      padding: 40px 20px;
    }

    .big-title {
      margin-bottom: 80px;

      @media(max-width: 700px) {
        margin-bottom: 40px;
      }
    }
  }
}