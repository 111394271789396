.site-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: .2px;
  color: #6D6D6D;

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #6D6D6D;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #6D6D6D;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #6D6D6D;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #6D6D6D;
  }

  .form-input {
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: .2px;
    color: #6D6D6D;

    &.file-section {
      display: flex;
      justify-content: space-between;

      @media (max-width: 630px) {
        flex-direction: column;
      }
    }
  }

  .form-select {
    width: 100%;
    padding: 20px 50px 20px 30px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: .2px;
    color: #6D6D6D;
    background: white url('/img/icon-chevron-bottom.svg') no-repeat right 30px center;
  }

  .form-textarea {
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: .2px;
    color: #6D6D6D;
    height: 200px;
  }

  .form-required {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: .2px;
    color: $colorAccent1;
    margin: 0;
    width: 100%;
    text-align: left;
  }

  .site-btn {
    margin-top: 60px;
    width: 100%;
    max-width: 275px;

    @media(max-width: 600px) {
      margin-top: 20px;
    }
  }
}

.alert-success {
  background: rgba(20, 181, 96, 0.5);
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  border-radius: 3px;
  margin: 0 0 10px;
  font-weight: 600;
  color: white;
}
